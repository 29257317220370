import React from "react";
import Animate from "../Components/Animate";

const Leaderboard = () => {
  const backgroundStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(10px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const messageStyle = {
    textAlign: "center",
    color: "white",
    padding: "20px",
  };

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  };

  const paragraphStyle = {
    margin: "10px 0",
    fontSize: "18px",
  };

  const buttonStyle = {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "18px",
    color: "white",
    backgroundColor: "#FFA500",
    border: "none",
    borderRadius: "25px",
    cursor: "pointer",
    textDecoration: "none",
  };

  const urlParams = new URLSearchParams(window.location.search);
  const hip = urlParams.get('hip');

  return (
    <Animate>
      <div style={backgroundStyle}>
        <div style={messageStyle}>
          <h2 style={titleStyle}>Invite Friends</h2>
          <p style={paragraphStyle}>
            Invite Friends. Top three users will get 100 TON and many more Metax Coin.
          </p>
          <p style={paragraphStyle}>Our leaderboard is currently under construction.</p>
          <p style={paragraphStyle}>Stay tuned for exciting updates and features.</p>
          <p style={paragraphStyle}>Track your progress and see how you rank among others.</p>
          <a href={`/ref?hip=${hip}`} style={buttonStyle}>Start Ref</a>
        </div>
      </div>
    </Animate>
  );
};

export default Leaderboard;

