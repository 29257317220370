import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import Tasks from "./pages/Tasks";
import Boost from "./pages/Boost";
import Stats from "./pages/Stats";
import Plutos from "./pages/TapHome.js";
import Ref from "./pages/Ref";
import DeviceCheck from "./Components/DeviceCheck"; // Adjust path as per your project structure
import CustomError from "./Components/CustomError"; // Import the CustomError component
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

// Always disable React DevTools
disableReactDevTools();

const disableContextMenu = (event) => {
  event.preventDefault();
};

const disableCopy = (event) => {
  event.preventDefault();
};

// Add global event listeners
document.addEventListener('contextmenu', disableContextMenu);
document.addEventListener('copy', disableCopy);
document.addEventListener('selectstart', disableCopy);
document.addEventListener('touchstart', disableContextMenu);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <CustomError />, // Set the custom error component
    children: [
      {
        path: "/",
        element: <Plutos />,
      },
      {
        path: "/ref",
        element: <Ref />,
      },
      {
        path: "/tasks",
        element: <Tasks />,
      },
      {
        path: "/boost",
        element: <Boost />,
      },
      {
        path: "/stats",
        element: <Stats />,
      },
      // Admin route removed
    ],
  },
  {
    path: "*",
    element: <CustomError />, // Catch-all route for undefined paths
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <DeviceCheck>
      <RouterProvider router={router} />
    </DeviceCheck>
  </React.StrictMode>
);

