import React, { useEffect, useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Animate from '../Components/Animate';
import coinsmall from "../images/coinsmall.webp";
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Spinner from '../Components/Spinner';
import TaskOne from '../Components/TaskOne';
import TaskTwo from '../Components/TaskTwo';
import TaskThree from '../Components/task3';
import TaskFour from '../Components/task4';
import TaskFive from '../Components/task5';
import TaskSix from '../Components/task6';
import Levels from '../Components/Levels.js';
import { IoCheckmarkSharp } from "react-icons/io5";
import congrats from "../images/celebrate.gif";
import MilestoneRewards from '../Components/MilestoneRewards';
import ReferralRewards from '../Components/Rewards';
import bronzeImg from "../images/bronze.webp";
import silverImg from "../images/sliver.webp";
import goldImg from "../images/gold.webp";
import platinumImg from "../images/platinum.webp";
import diamondImg from "../images/diamond.webp";
import masterImg from "../images/master.webp";
import grandMaster from "../images/master.webp";
import eliteLeague from "../images/master.webp";
import eliteMaster from "../images/master.webp";
import lottie from 'lottie-web';

// URLs for Lottie animations
const telegramAnimationUrl = 'https://lottie.host/2756b7e6-ef57-492d-841d-186464c8e14e/UMohgrCi7D.json';
const instagramAnimationUrl = 'https://lottie.host/b295987c-03e4-4b7f-b774-2f4061b76bd4/S3YCczJhht.json';
const youtubeAnimationUrl = 'https://lottie.host/d6212a1b-ab48-46e2-9f42-3d654c70cad4/pH0MTNdbyB.json';
const threadsAnimationUrl = 'https://lottie.host/70c5ee33-b008-4a7f-898c-e06be3b8a3fc/k8lkoWtfjs.json';
const specialAnimationUrl = 'https://lottie.host/2fd15b0e-cd4d-49be-9d2f-ad35fec43daf/CNgXOeAMhU.json';

const levelImages = {
  Bronze: bronzeImg,
  Silver: silverImg,
  Gold: goldImg,
  Platinum: platinumImg,
  Diamond: diamondImg,
  Master: masterImg,
  "Grand Master": masterImg, 
  "Elite League": masterImg, 
  "Elite Master": masterImg
};


const LottieAnimation = ({ url, style }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const animation = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: url,
      });

      return () => animation.destroy(); // Cleanup animation on component unmount
    }
  }, [url]);

  return <div ref={containerRef} style={style}></div>;
};

const Tasks = () => {
  const [id, setId] = useState(null);
  const [balance, setBalance] = useState(0);
  const [refBonus, setRefBonus] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [taskCompleted, setTaskCompleted] = useState({});
  const [level, setLevel] = useState({ name: "Bronze", imgUrl: "" });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);
  const [showLevels, setShowLevels] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [message, setMessage] = useState("");
  const [activeIndex, setActiveIndex] = useState(1);

  const handleMenu = (index) => {
    setActiveIndex(index);
  };

  const taskOne = () => {
    setShowModal(true);
    document.getElementById("footermain").style.zIndex = "50";
  };

  const taskTwo = () => {
    setShowModal2(true);
    document.getElementById("footermain").style.zIndex = "50";
  };

  const taskThree = () => {
    setShowModal3(true);
    document.getElementById("footermain").style.zIndex = "50";
  };

  const taskFour = () => {
    setShowModal4(true);
    document.getElementById("footermain").style.zIndex = "50";
  };

  const taskFive = () => {
    setShowModal5(true);
    document.getElementById("footermain").style.zIndex = "50";
  };

  const taskSix = () => {
    setShowModal6(true);
    document.getElementById("footermain").style.zIndex = "50";
  };

  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(`https://whatapi.cloud/users/${userId}`);
      const data = await response.json();
      console.log('User Data:', data);
      setBalance(data.shares);
      setRefBonus(data.refBonus);
      setLevel({ name: data.level, imgUrl: levelImages[data.level] });
      setId(data.id);
      setTaskCompleted({
        task1: data.task1,
        task2: data.task2,
        task3: data.task3,
        task4: data.task4,
        task5: data.task5,
        task6: data.task6,
        task7: data.task7,
        task8: data.task8,
        task9: data.task9,
        task10: data.task10,
      });
    } catch (e) {
      console.error('Error fetching user data:', e);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch(`https://whatapi.cloud/tasks`);
      const data = await response.json();
      console.log('Tasks Data:', data);
      setTasks(data);
    } catch (e) {
      console.error('Error fetching tasks:', e);
    }
  };

  useEffect(() => {
    const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
    if (userId) {
      setId(userId);
      const fetchData = async () => {
        await fetchUserData(userId);
        await fetchTasks();
        setLoading(false); // Set loading to false after fetching both user data and tasks
      };
      fetchData();
    }
  }, []);

  const levelsAction = () => {
    setShowLevels(true);
    document.getElementById("footermain").style.zIndex = "50";
  };

  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Animate>
          <div className='w-full justify-center flex-col space-y-3 px-5'>
            <div className='fixed top-0 left-0 right-0 pt-8 px-5'>
              <div className="flex space-x-2 justify-center items-center relative">
                {showCongrats && (
                  <div id="congrat" className='opacity-100 visible w-[80%] absolute pl-10 ease-in-out duration-500 transition-all'>
                    <img src={congrats} alt="congrats" className="w-full"/>
                  </div>
                )}
                <div className="w-[50px] h-[50px]">
                  <img src={coinsmall} className="w-full" alt="coin"/>
                </div>
                <h1 className="text-[#fff] text-[42px] font-extrabold">
                  {formatNumber(balance)}
                </h1>
              </div>
              <div onClick={levelsAction} className="w-full flex ml-[6px] space-x-1 items-center justify-center">
                <img src={levelImages[level.name]} className="w-[25px] relative" alt={level.name} />
                <h2 className="text-[#9d99a9] text-[20px] font-medium">{level.name}</h2>
                <MdOutlineKeyboardArrowRight className="w-[20px] h-[20px] text-[#9d99a9] mt-[2px]" />
              </div>
              <div className='bg-borders w-full px-5 h-[1px] !mt-5 !mb-5'></div>
              <div className='w-full border-[1px] border-borders rounded-[10px] p-1 flex items-center'>
                <div onClick={() => handleMenu(1)} className={`${activeIndex === 1 ? 'bg-light-orange' : ''} menu-button rounded-[6px] py-[12px] px-3 w-[33%] flex justify-center text-center items-center`}>
                  Special
                </div>
                <div onClick={() => handleMenu(2)} className={`${activeIndex === 2 ? 'bg-light-orange' : ''} menu-button rounded-[6px] py-[12px] px-3 w-[33%] flex justify-center text-center items-center`}>
                  Leagues
                </div>
                <div onClick={() => handleMenu(3)} className={`${activeIndex === 3 ? 'bg-light-orange' : ''} menu-button rounded-[6px] py-[12px] px-3 w-[33%] flex justify-center text-center items-center`}>
                  Ref Tasks
                </div>
              </div>
            </div>
            <div className='!mt-[204px] w-full h-[60vh] flex flex-col overflow-y-auto' style={{ scrollBehavior: 'smooth', paddingBottom: '70px' }}>
              <div className={`${activeIndex === 1 ? 'flex' : 'hidden'} alltaskscontainer flex-col w-full space-y-2`}>
                {tasks.map(task => (
                  <div key={task.id} onClick={() => {
                    if (task.id === '1') {
                      setShowModal(true);
                    } else if (task.id === '2') {
                      setShowModal2(true);
                    } else if (task.id === '3') {
                      setShowModal3(true);
                    } else if (task.id === '4') {
                      setShowModal4(true);
                    } else if (task.id === '5') {
                      setShowModal5(true);
                    } else if (task.id === '6') {
                      setShowModal6(true);
                    }
                  }} className='special-task-box'>
                    <div className='flex flex-1 items-center space-x-2'>
                      <div className='w-[50px]'>
                        {task.id === '1' || task.id === '2' ? (
                          <LottieAnimation url={telegramAnimationUrl} style={{ width: 50, height: 50 }} />
                        ) : null}
                        {task.id === '3' ? (
                          <LottieAnimation url={instagramAnimationUrl} style={{ width: 50, height: 50 }} />
                        ) : null}
                        {task.id === '4' ? (
                          <LottieAnimation url={specialAnimationUrl} style={{ width: 50, height: 50 }} />
                        ) : null}
                        {task.id === '5' ? (
                          <LottieAnimation url={youtubeAnimationUrl} style={{ width: 50, height: 50 }} />
                        ) : null}
                        {task.id === '6' ? (
                          <LottieAnimation url={threadsAnimationUrl} style={{ width: 50, height: 50 }} />
                        ) : null}
                      </div>
                      <div className='flex flex-col space-y-1'>
                        <span className='font-semibold'>{task.name}</span>
                        <div className='flex items-center space-x-1'>
                          <span className="w-[20px] h-[20px]">
                            <img src={coinsmall} className="w-full" alt="coin"/>
                          </span>
                          <span className='font-medium'>{formatNumber(task.reward)}</span>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      {taskCompleted[`task${task.id}`] ? (
                        <IoCheckmarkSharp className="w-[20px] h-[20px] text-[#5bd173] mt-[2px]"/>
                      ) : (
                        <MdOutlineKeyboardArrowRight className="w-[20px] h-[20px] text-[#e0e0e0] mt-[2px]"/>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className={`${activeIndex === 2 ? 'flex' : 'hidden'} alltaskscontainer flex-col w-full space-y-2`}>
                <MilestoneRewards/>
              </div>
              <div className={`${activeIndex === 3 ? 'flex' : 'hidden'} alltaskscontainer flex-col w-full space-y-2`}>
                <ReferralRewards/>
              </div>
            </div>
            {showModal && <TaskOne showModal={showModal} setShowModal={setShowModal} userId={id} />}
            {showModal2 && <TaskTwo showModal2={showModal2} setShowModal2={setShowModal2} userId={id} />}
            {showModal3 && <TaskThree showModal3={showModal3} setShowModal3={setShowModal3} userId={id} />}
            {showModal4 && <TaskFour showModal4={showModal4} setShowModal4={setShowModal4} userId={id} />}
            {showModal5 && <TaskFive showModal5={showModal5} setShowModal5={setShowModal5} userId={id} />}
            {showModal6 && <TaskSix showModal6={showModal6} setShowModal6={setShowModal6} userId={id} />}
            <Levels showLevels={showLevels} setShowLevels={setShowLevels} />
          </div>
          <Outlet />
        </Animate>
      )}
      <style jsx>{`
        .menu-button {
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
        }
        .bg-light-orange {
          background-color: rgba(255, 165, 0, 0.2); /* Light orange with transparency */
        }
        .special-task-box {
          background-color: rgba(255, 165, 0, 0.2); /* Light orange with transparency */
          backdrop-filter: blur(8px);
          border-radius: 10px;
          padding: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: transform 0.3s ease-in-out;
        }
        .special-task-box:hover {
          transform: translateY(-5px);
        }
        .alltaskscontainer > div {
          background-color: rgba(255, 255, 255, 0.1); /* Light transparent background */
          backdrop-filter: blur(8px);
          border-radius: 10px;
          padding: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid rgba(255, 255, 255, 0.2); /* Visible corners */
        }
      `}</style>
    </>
  );
};

export default Tasks;

