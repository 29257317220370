import React, { useState, useEffect } from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import congratspic from "../images/celebrate.gif";
import coinsmall from "../images/coinsmall.webp";
import ref from "../images/ref.webp";

const friendsRewards = [
  { title: 'Invite 3 friends', referralsRequired: 3, bonusAward: 50000 },
  { title: 'Invite 10 friends', referralsRequired: 10, bonusAward: 150000 },
  { title: 'Invite 25 friends', referralsRequired: 25, bonusAward: 250000 },
  { title: 'Invite 50 friends', referralsRequired: 50, bonusAward: 300000 },
  { title: 'Invite 100 friends', referralsRequired: 100, bonusAward: 600000 },
  { title: 'Invite 250 friends', referralsRequired: 250, bonusAward: 1500000 },
  { title: 'Invite 500 friends', referralsRequired: 500, bonusAward: 4000000 },
  { title: 'Invite 1000 friends', referralsRequired: 1000, bonusAward: 10000000 },
  { title: 'Invite 2500 friends', referralsRequired: 2500, bonusAward: 25000000 },
  { title: 'Invite 5000 friends', referralsRequired: 5000, bonusAward: 50000000 },
  { title: 'Invite 10000 friends', referralsRequired: 10000, bonusAward: 100000000 },
  { title: 'Invite 25000 friends', referralsRequired: 25000, bonusAward: 200000000 },
  { title: 'Invite 50000 friends', referralsRequired: 50000, bonusAward: 500000000 },
  { title: 'Invite 100000 friends', referralsRequired: 100000, bonusAward: 1000000000 },
];

const MilestoneRewards = () => {
  const [tapBalance, setTapBalance] = useState(0);
  const [referrals, setReferrals] = useState(0);
  const [balance, setBalance] = useState(0);
  const [claimedMilestones, setClaimedMilestones] = useState([]);
  const [congrats, setCongrats] = useState(false);

  useEffect(() => {
    const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;

    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://whatapi.cloud/users/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await response.json();
        setReferrals(data.ref_count);
        setTapBalance(data.total_balance);
        setClaimedMilestones(data.claimedRewards || []);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleClaim = async (milestone) => {
    const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
    if (referrals >= milestone.referralsRequired && !claimedMilestones.includes(milestone.title)) {
      try {
        const response = await fetch(`https://whatapi.cloud/${userId}/reward`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ reward: milestone.bonusAward, milestoneName: milestone.title }),
        });
        if (!response.ok) {
          throw new Error('Failed to claim reward');
        }
        const data = await response.json();
        setBalance(balance + milestone.bonusAward);
        setClaimedMilestones([...claimedMilestones, milestone.title]);
        setCongrats(true);

        setTimeout(() => {
          setCongrats(false);
        }, 4000);
      } catch (error) {
        console.error('Error claiming milestone reward:', error);
      }
    } else {
      console.error('Already Claimed or insufficient balance');
    }
  };

  const formatNumberClaim = (num) => {
    if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + "M";
    }
  };

  return (
    <div className="flex flex-col h-full" style={{ height: '100vh' }}>
      <div className="flex-1 overflow-y-auto" style={{ paddingBottom: '100px' }}>
        {friendsRewards.map((milestone) => {
          const progress = (referrals / milestone.referralsRequired) * 100;
          const isClaimable = referrals >= milestone.referralsRequired && !claimedMilestones.includes(milestone.title);
          const isClaimed = claimedMilestones.includes(milestone.title);

          return (
            <div key={milestone.title} className='bg-cards rounded-[10px] p-[14px] flex flex-wrap justify-between items-center mb-2'>
              <div className='flex flex-1 items-center space-x-2'>
                <div>
                  <img src={ref} alt={milestone.title} className='w-[55px]' />
                </div>
                <div className='flex flex-col space-y-1'>
                  <span className='font-semibold'>
                    {milestone.title}
                  </span>
                  <div className='flex items-center space-x-1'>
                    <span className="w-[20px] h-[20px]">
                      <img src={coinsmall} className="w-full" alt="coin" />
                    </span>
                    <span className='font-medium'>
                      {formatNumberClaim(milestone.bonusAward)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  disabled={!isClaimable}
                  onClick={() => handleClaim(milestone)}
                  className={` ${isClaimable ? 'bg-btn text-white' : "bg-btn2 text-[#fff6]"} relative rounded-[8px] font-semibold py-2 px-3 mr-2`}>
                  {isClaimable ? 'Claim' : isClaimed ? <IoCheckmarkCircle size={24} /> : 'Pending'}
                </button>
              </div>
              <div className='flex w-full mt-2 p-[4px] items-center bg-energybar rounded-[10px] border-[1px] border-borders'>
                <div className={`h-[8px] rounded-[8px] ${progress >= 100 ? 'bg-btn' : 'bg-btn'}`} style={{ width: `${progress > 100 ? 100 : progress}%` }}>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full absolute top-[-35px] left-0 right-0 flex justify-center z-20 pointer-events-none select-none">
        {congrats ? <img src={congratspic} alt="congrats" className="w-[80%]" /> : null}
      </div>
      <div className={`${congrats === true ? "visible bottom-6" : "invisible bottom-[-10px]"} z-[60] ease-in duration-300 w-full fixed left-0 right-0 px-4`}>
        <div className="w-full text-[#54d192] flex items-center space-x-2 px-4 bg-[#121620ef] h-[50px] rounded-[8px]">
          <IoCheckmarkCircle size={24} className="" />
          <span className="font-medium">
            Good
          </span>
        </div>
      </div>
    </div>
  );
};

export default MilestoneRewards;

