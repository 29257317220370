import React from 'react';

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const DeviceCheck = ({ children }) => {
  if (!isMobileDevice()) {
    return (
      <div className='w-full flex h-full justify-center px-5 items-center font-medium text-[20px]'>
        <div className='w-full pt-24 text-center flex flex-col space-y-3 justify-center items-center'>
          <p className='text-[28px] font-semibold'>
            Mobile rocks for gaming 😎 Open on your mobile device to play now!
          </p>
          <img
            src='/tapme.webp'
            alt='plutotaps'
            className='w-[250px] rounded-[25px] border-[2px] border-[#0000ff]'
          />
        </div>
      </div>
    );
  }
  return <>{children}</>;
};

export default DeviceCheck;

