import React from "react";
import ref from "../images/ref.webp";
import boost from "../images/boost.webp";
import tasks from "../images/tasks.webp";
import stats from "../images/stats.webp";
import coinsmall from "../images/coinsmall.webp";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background: linear-gradient(90deg, #add8e6, #ffa07a, #dda0dd); /* Gradient background */
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2); /* Adding a shadow */
  z-index: 1000; /* Ensuring it stays on top */
  backdrop-filter: blur(10px); /* Adding blur effect for a glassy look */
`;

const FooterLink = styled(NavLink)`
  width: 20%;
  height: 65px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adding a shadow to buttons */

  &.active {
    background-color: #9370db; /* Light purple color when active */
    border-color: #9370db;
    color: #fff;
    transform: translateY(-5px); /* Lift effect on active */
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Slightly darker on hover */
  }

  img {
    margin-bottom: 5px;
    transition: transform 0.3s; /* Smooth image scaling */
  }

  &:hover img {
    transform: scale(1.1); /* Scale up image on hover */
  }

  span {
    font-weight: bold; /* Make the text bold */
    letter-spacing: 0.5px; /* Slight spacing between letters */
    transition: color 0.3s; /* Smooth color transition */
  }

  &:hover span {
    color: #ffd700; /* Change text color on hover */
  }
`;

const Footer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hip = queryParams.get('hip');

  return (
    <FooterContainer>
      <FooterLink to={`/ref?hip=${hip}`} activeClassName="active">
        <img src={ref} className="w-[32px] -mb-1" alt="ref" />
        <span>Ref</span>
      </FooterLink>
      <FooterLink to={`/tasks?hip=${hip}`} activeClassName="active">
        <img src={tasks} className="w-[30px]" alt="tasks" />
        <span>Tasks</span>
      </FooterLink>
      <FooterLink to={`/?hip=${hip}`} activeClassName="active">
        <img src={coinsmall} className="w-[30px] -mb-[1px]" alt="tap" />
        <span>Tap</span>
      </FooterLink>
      <FooterLink to={`/boost?hip=${hip}`} activeClassName="active">
        <img src={boost} className="w-[28px] -mb-[2px]" alt="boost" />
        <span>Boost</span>
      </FooterLink>
      <FooterLink to={`/stats?hip=${hip}`} activeClassName="active">
        <img src={stats} className="w-[24px]" alt="stats" />
        <span>Stats</span>
      </FooterLink>
    </FooterContainer>
  );
};

export default Footer;

