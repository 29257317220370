import React, { useState, useEffect } from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import congratspic from "../images/celebrate.gif";
import coinsmall from "../images/coinsmall.webp";
import bronzeImg from "../images/bronze.webp";
import silverImg from "../images/sliver.webp";
import goldImg from "../images/gold.webp";
import platinumImg from "../images/platinum.webp";
import diamondImg from "../images/diamond.webp";
import masterImg from "../images/master.webp";

const milestones = [
  { name: 'Bronze', icon: bronzeImg, tapBalanceRequired: 1000, reward: 50000 },
  { name: 'Silver', icon: silverImg, tapBalanceRequired: 50000, reward: 100000 },
  { name: 'Gold', icon: goldImg, tapBalanceRequired: 500000, reward: 250000 },
  { name: 'Platinum', icon: platinumImg, tapBalanceRequired: 1000000, reward: 500000 },
  { name: 'Diamond', icon: diamondImg, tapBalanceRequired: 2500000, reward: 1000000 },
  { name: 'Master', icon: masterImg, tapBalanceRequired: 5000000, reward: 2500000 },
  { name: 'Grandmaster', icon: masterImg, tapBalanceRequired: 10000000, reward: 5000000 },
  { name: 'Elite Master', icon: masterImg, tapBalanceRequired: 100000000, reward: 10000000 },
];

const MilestoneRewards = () => {
  const [tapBalance, setTapBalance] = useState(0);
  const [balance, setBalance] = useState(0);
  const [claimedMilestones, setClaimedMilestones] = useState([]);
  const [congrats, setCongrats] = useState(false);

  useEffect(() => {
    const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;

    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://whatapi.cloud/users/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await response.json();
        setTapBalance(data.total_balance);
        setClaimedMilestones(data.claimedRewards || []);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleClaim = async (milestone) => {
    const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
    if (tapBalance >= milestone.tapBalanceRequired && !claimedMilestones.includes(milestone.name)) {
      try {
        const response = await fetch(`https://whatapi.cloud/${userId}/rewardd`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ reward: milestone.reward, milestoneName: milestone.name }),
        });
        if (!response.ok) {
          throw new Error('Failed to claim reward');
        }
        const data = await response.json();
        setBalance(balance + milestone.reward);
        setClaimedMilestones([...claimedMilestones, milestone.name]);
        setCongrats(true);

        setTimeout(() => {
          setCongrats(false);
        }, 4000);
      } catch (error) {
        console.error('Error claiming milestone reward:', error);
      }
    } else {
      console.error('Already Claimed or insufficient balance');
    }
  };

  const formatNumberClaim = (num) => {
    if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  return (
    <div className="w-full flex flex-col space-y-4">
      <div className="w-full h-96 overflow-y-auto">
        {milestones.map((milestone) => {
          const progress = (tapBalance / milestone.tapBalanceRequired) * 100;
          const isClaimable = tapBalance >= milestone.tapBalanceRequired && !claimedMilestones.includes(milestone.name);
          const isClaimed = claimedMilestones.includes(milestone.name);

          return (
            <div key={milestone.name} className='bg-cards rounded-[10px] p-[14px] flex flex-wrap justify-between items-center mb-2'>
              <div className='flex flex-1 items-center space-x-2'>
                <div>
                  <img src={milestone.icon} alt={milestone.name} className='w-[55px]' />
                </div>
                <div className='flex flex-col space-y-1'>
                  <span className='font-semibold'>
                    {milestone.name}
                  </span>
                  <div className='flex items-center space-x-1'>
                    <span className="w-[20px] h-[20px]">
                      <img src={coinsmall} className="w-full" alt="coin" />
                    </span>
                    <span className='font-medium'>
                      {formatNumberClaim(milestone.reward)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  disabled={!isClaimable}
                  onClick={() => handleClaim(milestone)}
                  className={` ${isClaimable ? 'bg-btn text-white' : "bg-btn2 text-[#fff6]"} relative rounded-[8px] font-semibold py-2 px-3 mr-2`}>
                  {isClaimable ? 'Claim' : isClaimed ? <IoCheckmarkCircle size={24} /> : 'Pending'}
                </button>
              </div>
              <div className='flex w-full mt-2 p-[4px] items-center bg-energybar rounded-[10px] border-[1px] border-borders'>
                <div className={`h-[8px] rounded-[8px] ${progress >= 100 ? 'bg-btn' : 'bg-btn'}`} style={{ width: `${progress > 100 ? 100 : progress}%` }}>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full absolute top-[-35px] left-0 right-0 flex justify-center z-20 pointer-events-none select-none">
        {congrats ? <img src={congratspic} alt="congrats" className="w-[80%]" /> : null}
      </div>
      <div className={`${congrats === true ? "visible bottom-6" : "invisible bottom-[-10px]"} z-[60] ease-in duration-300 w-full fixed left-0 right-0 px-4`}>
        <div className="w-full text-[#54d192] flex items-center space-x-2 px-4 bg-[#121620ef] h-[50px] rounded-[8px]">
          <IoCheckmarkCircle size={24} className="" />
          <span className="font-medium">
            Good
          </span>
        </div>
      </div>
    </div>
  );
};

export default MilestoneRewards;

