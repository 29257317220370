// src/components/CustomError.js
import React, { useEffect } from 'react';

const CustomError = () => {
  useEffect(() => {
    // Instant redirect to the Telegram bot
    window.location.href = 'https://telegram.me/Metaxcoiniobot';
  }, []);

  return null; // No need to render anything since we are redirecting immediately
};

export default CustomError;
