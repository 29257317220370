import React from 'react'

const Spinner = () => {
  return (
<div className="spinner">
<div className="spin1 animate-spin">
    <div className="spin2">

</div>
    <div className="spin3">

</div><div className="spin4">

</div><div className="spin5">

</div>

<div className="spin6">

</div><div className="spin7">

</div><div className="spin8">

</div><div className="spin9">

</div></div>
    
</div>
  )
}

export default Spinner
