import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import styled, { keyframes } from "styled-components";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Animate from '../Components/Animate';
import Spinner from '../Components/Spinner';
import Levels from '../Components/Levels.js';
import flash from "../images/flash.webp";
import coinsmall from "../images/coinsmall.webp";
import coin1 from "../images/tapme1.webp";
import bronzeImg from "../images/bronze.webp";
import silverImg from "../images/sliver.webp";
import goldImg from "../images/gold.webp";
import platinumImg from "../images/platinum.webp";
import diamondImg from "../images/diamond.webp";
import masterImg from "../images/master.webp";

import { EnergyContext } from '../context/EnergyContext';

const levelImages = {
  Bronze: bronzeImg,
  Silver: silverImg,
  Gold: goldImg,
  Platinum: platinumImg,
  Diamond: diamondImg,
  Master: masterImg,
  "Grand Master": masterImg, 
  "Elite League": masterImg, 
  "Elite Master": masterImg
};


const slideUp = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-350px);
  }
`;

const SlideUpText = styled.div`
  position: absolute;
  animation: ${slideUp} 3s ease-out;
  font-size: 2.1em;
  color: #ffffffa6;
  font-weight: 600;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  pointer-events: none;
`;

const glitch = keyframes`
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
`;

const GlitchEffect = styled.div`
  position: absolute;
  animation: ${glitch} 1s infinite;
  font-size: 2.1em;
  color: #ffffffa6;
  font-weight: 600;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  pointer-events: none;
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
`;

const EnergyFill = styled.div`
  background: linear-gradient(to right, orange, yellow, green); /* Gradient colors */
  height: 12px;
  border-radius: 6px;
  width: ${({ percentage }) => percentage}%;
`;

const Message = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #25d366;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  z-index: 10;
  animation: fadeOut 3s forwards;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const Plutos = () => {
  const imageRef = useRef(null);
  const [clicks, setClicks] = useState([]);
  const [batterylev, setBattery] = useState(1);
  const [chargeLevel, setChargeLevel] = useState(1);
  const [balance, setBalance] = useState(0);
  const [userid, setUserid] = useState(null);
  const [tapBalance, setTapBalance] = useState(0);
  const [tapValue, setTapValue] = useState(1);
  const [freeGuru, setFreeGuru] = useState(0);
  const [refiller, setRefiller] = useState(0);
  const [fullTank, setFullTank] = useState(0);
  const [refBonus, setRefBonus] = useState(0);
  const [level, setLevel] = useState({ name: "Bronze", imgUrl: levelImages["Bronze"] });
  const [loading, setLoading] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [showLevels, setShowLevels] = useState(false);
  const debounceTimerRef = useRef(null);
  const { energy, setEnergy, displayEnergy, setDisplayEnergy } = useContext(EnergyContext);
  const [showMessage, setShowMessage] = useState(false);
  const charew = batterylev * 500;
  const refillTime = 1000; // 1 second in milliseconds

  const fetchUserStatsFromAPI = useCallback(async (userid) => {
    console.log(`Fetching user stats for user ID: ${userid}`);
    try {
      const response = await fetch(`https://whatapi.cloud/users/${userid}`);
      if (!response.ok) {
        throw new Error("Error fetching user stats");
      }
      const data = await response.json();
      console.log("Fetched user data:", data);
      setBalance(data.shares);
      setEnergy(data.energy);
      setDisplayEnergy(data.energy);
      setFreeGuru(data.free_guru);
      setRefiller(data.energy);
      setFullTank(data.full_tank);
      setTapValue(data.tap_level);
      setBattery(data.energy_level);
      setChargeLevel(data.charge_level);
      setRefBonus(data.ref_bonus || 0);
      
      const fetchedLevel = data.level; // Example: fetched from API
      setLevel({ name: fetchedLevel, imgUrl: levelImages[fetchedLevel] });
    } catch (e) {
      console.error("Error fetching user stats: ", e);
    }
  }, []);

  const updateUserStatsInAPI = async (userid, newBalance, newTapBalance, newEnergy) => {
    console.log("Updating user stats:", { userid, newBalance, newTapBalance, newEnergy });
    try {
      const response = await fetch(`https://whatapi.cloud/users/${userid}/stats`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          shares: newBalance,
          tapBalance: newTapBalance,
          energy: newEnergy,
        })
      });
      if (!response.ok) {
        throw new Error("Error updating user stats");
      }
    } catch (e) {
      console.error("Error updating user stats:", e);
    }
  };

  const handleClick = (e) => {
    if (energy <= 0) return;
    console.log("Handle click event:", e);
    triggerHapticFeedback();

    const { offsetX, offsetY, target } = e.nativeEvent;
    const { clientWidth, clientHeight } = target;

    const horizontalMidpoint = clientWidth / 2;
    const verticalMidpoint = clientHeight / 2;

    const animationClass =
      offsetX < horizontalMidpoint
        ? 'wobble-left'
        : offsetX > horizontalMidpoint
        ? 'wobble-right'
        : offsetY < verticalMidpoint
        ? 'wobble-top'
        : 'wobble-bottom';

    imageRef.current.classList.remove(
      'wobble-top',
      'wobble-bottom',
      'wobble-left',
      'wobble-right'
    );

    imageRef.current.classList.add(animationClass);

    setTimeout(() => {
      imageRef.current.classList.remove(animationClass);
    }, 500);

    const rect = e.target.getBoundingClientRect();
    const newClick = {
      id: Date.now(),
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };

    setClicks((prevClicks) => [...prevClicks, newClick]);

    const updatedCount = balance + getTapIncrement();
    const updatedEnergy = energy - getTapIncrement();

    setEnergy(updatedEnergy);
    setBalance(updatedCount);

    setClickCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount >= 10) {
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000); // Hide the message after 3 seconds
        updateUserStatsInAPI(userid, updatedCount, tapBalance, updatedEnergy);
        return 0;
      }
      return newCount;
    });

    setTimeout(() => {
      setClicks((prevClicks) =>
        prevClicks.filter((click) => click.id !== newClick.id)
      );
    }, 1000);

    clearTimeout(debounceTimerRef.current);
  };

  const triggerHapticFeedback = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isIOS && window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    } else if (isAndroid && 'vibrate' in navigator) {
      navigator.vibrate(50); // Vibrate for 50ms
    } else {
      console.warn('Haptic feedback not supported on this device.');
    }
  };

  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  const saveRefereeIdToAPI = useCallback(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const telegramUsername = window.Telegram.WebApp.initDataUnsafe?.user?.username;
    const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
    const telegramUserid = window.Telegram.WebApp.initDataUnsafe?.user?.id;
    const telegramName = window.Telegram.WebApp.initDataUnsafe?.user?.first_name;
    const telegramLastName = window.Telegram.WebApp.initDataUnsafe?.user?.last_name;

    const fullName = `${telegramName} ${telegramLastName}`;

    let refereeId = queryParams.get("ref");
    if (refereeId) {
      refereeId = refereeId.replace(/\D/g, "");
    }

    if (telegramUserid) {
      await storeUserData(fullName, telegramUsername, telegramUserid, refereeId);
    }
  }, []);

  const storeUserData = async (fullname, username, userid, refereeId) => {
    console.log("Storing user data:", { fullname, username, userid, refereeId });
    try {
      const response = await fetch("https://whatapi.cloud/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          player: {
            id: userid,
            name: username,
            full_name: fullname,
            login_ts: Date.now(),
            time: Date.now(),
            energy: 500,
            shares: 0,
            energy_level: 1,
            charge_level: 1,
            tap_level: 1,
            tap_bot: false,
            boost: [
              { type: "energy", cnt: 0, end: 0 },
              { type: "turbo", cnt: 0, end: 0 }
            ],
            ref_count: 0,
            ref_ids: [],
            ref_by: refereeId ? [refereeId] : []
          }
        })
      });
      if (!response.ok) {
        throw new Error("Error storing user data");
      }
    } catch (e) {
      console.error("Error storing user data:", e);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const telegramName = window.Telegram.WebApp.initDataUnsafe?.user?.first_name;
    const telegramLastName = window.Telegram.WebApp.initDataUnsafe?.user?.last_name;
    const telegramUsername = window.Telegram.WebApp.initDataUnsafe?.user?.username;
    
    const telegramUserid = window.Telegram.WebApp.initDataUnsafe?.user?.id;

    
    if (telegramUserid) {
      setUserid(telegramUserid);
    }

    if (telegramUserid) {
      saveRefereeIdToAPI();
    }

    if (telegramUserid) {
      fetchUserStatsFromAPI(telegramUserid)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setBalance(0);
          setEnergy(500);
          setTapValue({ level: 1, value: 1 });
          setBattery({ level: 1, energy: 500 });
          setChargeLevel(1);
          setFreeGuru(0);
          setRefiller(0);
          setFullTank(0);
          setRefBonus(0);
          setLoading(false);
        });
    }

    

  }, [fetchUserStatsFromAPI, saveRefereeIdToAPI]);

  const getTapIncrement = () => {
    return tapValue || 1;
  };

  const energyPercentage = (energy / charew ) * 100;

  console.log("Component state:", {
    clicks,
    balance,
    userid,
    batterylev,
    chargeLevel,
    tapBalance,
    energy,
    chargeLevel,
    batterylev,
    tapValue,
    freeGuru,
    refiller,
    fullTank,
    refBonus,
    level,
    loading,
    clickCount,
    showLevels
  });

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Animate>
          {showMessage && <Message>Tap Me More</Message>}
          <div className="w-full flex justify-center flex-col overflow-hidden relative">
            <div className="flex space-x-[2px] justify-center items-center">
              <div className="w-[50px] h-[50px]">
                <img src={coinsmall} className="w-full" alt="coin" />
              </div>
              <h1 className="text-[#fff] text-[42px] font-extrabold">
                {formatNumber(balance + refBonus)} <br/>
              </h1>
            </div>
            <div className="w-full ml-[6px] flex space-x-1 items-center justify-center">
              <img
                src={levelImages[level.name]}
                className="w-[25px] relative"
                alt={level.name}
              />
              <h2 onClick={() => setShowLevels(true)} className="text-[#9d99a9] text-[20px] font-medium">
                {level.name}
              </h2>
              <MdOutlineKeyboardArrowRight className="w-[20px] h-[20px] text-[#9d99a9] mt-[2px]" />
            </div>
            <div className="w-full flex justify-center items-center pt-7 pb-24 relative">
              <div className="bg-[#efc26999] blur-[50px] absolute rotate-[35deg] w-[400px] h-[160px] top-10 -left-40 rounded-full"></div>
              <div className="w-[350px] h-[350px] relative flex items-center justify-center">
                <img src={flash} alt='err' className={`absolute w-[330px] rotate-45 hidden`} />
                <div className="image-container">
                  <Container>
                    <img
                      onPointerDown={handleClick}
                      ref={imageRef}
                      src={coin1}
                      alt="Wobble"
                      className="wobble-image !w-[250px] select-none"
                    />
                    {clicks.map((click) => (
                      <SlideUpText key={click.id} x={click.x} y={click.y}>
                        +{tapValue}
                      </SlideUpText>
                    ))}
                  </Container>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-6 fixed bottom-[120px] left-0 right-0 justify-center items-center px-5">
              <div className="flex flex-col w-full items-center justify-center">
                <div className="flex pb-[6px] space-x-1 items-center justify-center text-[#fff]">
                  
                  <div className="">
                    <span className="text-[18px] font-bold">{energy}</span>
                    <span className="text-[14px] font-medium">/ {500 * batterylev}</span>
                  </div>
                </div>
                <div className="flex w-full p-[4px] h-[20px] items-center bg-energybar rounded-[12px] border-[1px] border-borders2">
                  <EnergyFill percentage={energyPercentage} />
                </div>
              </div>
            </div>
            <Levels showLevels={showLevels} setShowLevels={setShowLevels} />
          </div>
        </Animate>
      )}
    </>
  );
};

export default Plutos;


