import React, { useEffect, useState } from "react";
import Animate from "../Components/Animate";
import { Outlet } from "react-router-dom";
import Spinner from "../Components/Spinner";
import bronze from "../images/bronze.webp";
import silver from "../images/gold.webp";
import platinum from "../images/platinum.webp";
import diamond from "../images/diamond.webp";
import master from "../images/master.webp";
import grandMaster from "../images/gold.webp";
import eliteLeague from "../images/gold.webp";
import eliteMaster from "../images/gold.webp";
import coinsmall from "../images/coinsmall.webp";
import giftIcon from "../images/gift.webp";
import giftIco from "../images/pre.webp";

const Ref = () => {
  const [count, setCount] = useState(0);
  const [username, setUsername] = useState("");
  const [idme, setIdme] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);

  const formattedCount = new Intl.NumberFormat().format(count).replace(/,/g, " ");

  useEffect(() => {
    const telegramUsername = window.Telegram.WebApp.initDataUnsafe?.user?.username;
    const telegramUserid = window.Telegram.WebApp.initDataUnsafe?.user?.id;

    if (telegramUsername) {
      setUsername(telegramUsername);
    }
    if (telegramUserid) {
      setIdme(telegramUserid);
    }

    fetchAllUsers(telegramUserid);
  }, []);

  const fetchAllUsers = async (userId) => {
    try {
      const response = await fetch(`https://whatapi.cloud/${userId}/ref`);
      const data = await response.json();

      if (!data.ref_ids) {
        throw new Error("Invalid data structure");
      }

      const allUsers = data.ref_ids.map((ref) => ({
        ...ref.details,
        refereeId: ref.ref_id,
        ref_count: ref.details.ref_count
      }));

      setUsers(allUsers);
      setLoading(false);
      setCount(data.ref_count);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    const telegramUserid = window.Telegram.WebApp.initDataUnsafe?.user?.id;

    if (telegramUserid) {
      setIdme(telegramUserid);
    }

    const reflink = `https://t.me/Metaxcoiniobot?start=${telegramUserid}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(reflink)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 10000);
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = reflink;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error("Failed to copy:", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const getTierImage = (shares) => {
    if (shares < 5000) return bronze;
    if (shares < 250000) return silver;
    if (shares < 500000) return platinum;
    if (shares < 1000000) return diamond;
    if (shares < 2500000) return master;
    if (shares < 5000000) return grandMaster;
    if (shares < 10000000) return eliteLeague;
    return eliteMaster;
  };

  const getTierName = (shares) => {
    if (shares < 5000) return "Bronze";
    if (shares < 250000) return "Silver";
    if (shares < 500000) return "Platinum";
    if (shares < 1000000) return "Diamond";
    if (shares < 2500000) return "Master";
    if (shares < 5000000) return "Grand Master";
    if (shares < 10000000) return "Elite League";
    return "Elite Master";
  };

  const userWithMostRefs = users.reduce((max, user) => (user.ref_count > max.ref_count ? user : max), users[0] || {});
  const userWithMostCoins = users.reduce((max, user) => (user.shares > max.shares ? user : max), users[0] || {});

  const pinnedUsers = [userWithMostRefs, userWithMostCoins];
  const otherUsers = users.filter(user => !pinnedUsers.includes(user));

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Animate>
            <div className="w-full justify-center flex-col space-y-3 px-5">
              <div className="flex space-y-0 flex-col justify-center items-center">
                <img src={giftIcon} alt="Gift" className="w-8 h-8 mb-2" />
                <h1 className="text-[#fff] -mb-2 text-[42px] font-semibold">
                  {formattedCount ? formattedCount : "0"} Users
                </h1>
                <span className="text-[#6ed86e] font-semibold text-[16px]">
                  Total Referrals: {count}
                </span>
              </div>

              <div className="w-full bg-cards rounded-[12px] px-3 py-4 flex flex-col">
                <span className="w-full flex justify-between items-center pb-2">
                  <h2 className="text-[18px] font-semibold">My invite link:</h2>
                  <span
                    onClick={copyToClipboard}
                    className="bg-orange-500 font-medium py-[6px] px-4 rounded-[12px] flex items-center justify-center text-[16px]"
                  >
                    {copied ? <span>Copied!</span> : <span>Copy</span>}
                  </span>
                </span>
                <div className="text-[#9a96a6] text-[13px]">
                  https://t.me/Metaxcoiniobot?start={idme}
                </div>
              </div>
              <div className="bg-borders w-full px-5 h-[1px] !mt-6"></div>

              <div className="w-full flex flex-col">
                <h3 className="text-[22px] font-semibold pb-[16px]">
                  <img src={giftIco} alt="Gift" className="inline-block w-6 h-6 mr-2" />My Referrals:
                </h3>

                <div className="w-full flex flex-col space-y-3 overflow-y-auto max-h-[400px]">
                  {users.length > 0 ? (
                    <>
                      {pinnedUsers.map((user, index) => (
                        <div
                          key={`pinned-${index}`}
                          className="bg-[rgba(255,255,255,0.1)] backdrop-blur-sm rounded-[10px] p-[14px] flex flex-wrap justify-between items-center border border-gray-500"
                        >
                          <div className="flex flex-1 flex-col space-y-1">
                            <div className="text-[#fff] pl-1 text-[16px] font-semibold">
                              {user.fullname}
                              {user === userWithMostRefs && (
                                <span className="ml-2 text-[12px] bg-blue-300 text-black px-2 py-1 rounded-full">Influencer</span>
                              )}
                              {user === userWithMostCoins && (
                                <span className="ml-2 text-[12px] bg-yellow-300 text-black px-2 py-1 rounded-full">Shares King</span>
                              )}
                            </div>

                            <div className="flex items-center space-x-1 text-[14px] text-[#e5e5e5]">
                              <div className="">
                                <img src={getTierImage(user.shares)} alt="tier" className="w-[18px]" />
                              </div>
                              <span className="font-medium text-[#9a96a6]">
                                {getTierName(user.shares)}
                              </span>
                              <span className="bg-[#bdbdbd] w-[1px] h-[13px] mx-2"></span>

                              <span className="w-[20px]">
                                <img src={coinsmall} className="w-full" alt="coin" />
                              </span>
                              <span className="font-normal text-[#ffffff] text-[15px]">
                                {user.shares}
                              </span>
                              <span className="bg-[#bdbdbd] w-[1px] h-[13px] mx-2"></span>

                              <span className="font-normal text-[#ffffff] text-[15px]">
                                Referrals: {user.ref_count}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                      {otherUsers.map((user, index) => (
                        <div
                          key={index}
                          className="bg-[rgba(255,255,255,0.1)] backdrop-blur-sm rounded-[10px] p-[14px] flex flex-wrap justify-between items-center border border-gray-500"
                        >
                          <div className="flex flex-1 flex-col space-y-1">
                            <div className="text-[#fff] pl-1 text-[16px] font-semibold">
                              {user.fullname}
                            </div>

                            <div className="flex items-center space-x-1 text-[14px] text-[#e5e5e5]">
                              <div className="">
                                <img src={getTierImage(user.shares)} alt="tier" className="w-[18px]" />
                              </div>
                              <span className="font-medium text-[#9a96a6]">
                                {getTierName(user.shares)}
                              </span>
                              <span className="bg-[#bdbdbd] w-[1px] h-[13px] mx-2"></span>

                              <span className="w-[20px]">
                                <img src={coinsmall} className="w-full" alt="coin" />
                              </span>
                              <span className="font-normal text-[#ffffff] text-[15px]">
                                {user.shares}
                              </span>
                              <span className="bg-[#bdbdbd] w-[1px] h-[13px] mx-2"></span>

                              <span className="font-normal text-[#ffffff] text-[15px]">
                                Referrals: {user.ref_count}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="w-full text-center text-[16px] py-12 font-medium">
                      You don't have referrals😭
                    </p>
                  )}
                </div>
              </div>
            </div>
            <Outlet />
          </Animate>
        </>
      )}
    </>
  );
};

export default Ref;

