import React, { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

import bronzeImg from "../images/bronze.webp";
import silverImg from "../images/sliver.webp";
import goldImg from "../images/gold.webp";
import platinumImg from "../images/platinum.webp";
import diamondImg from "../images/diamond.webp";
import masterImg from "../images/master.webp";

const userLevels = [
  { name: 'Bronze', icon: bronzeImg, tapBalanceRequired: 1000 },
  { name: 'Silver', icon: silverImg, tapBalanceRequired: 50000 },
  { name: 'Gold', icon: goldImg, tapBalanceRequired: 500000 },
  { name: 'Platinum', icon: platinumImg, tapBalanceRequired: 1000000 },
  { name: 'Diamond', icon: diamondImg, tapBalanceRequired: 2500000 },
  { name: 'Master', icon: masterImg, tapBalanceRequired: 5000000 },
  { name: 'Grandmaster', icon: masterImg, tapBalanceRequired: 10000000 },
  { name: 'Elite Master', icon: masterImg, tapBalanceRequired: 100000000 },
];


const Levels = ({ showLevels, setShowLevels }) => {
  const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
  const [tapBalance, setTapBalance] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [displayedLevelIndex, setDisplayedLevelIndex] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://whatapi.cloud/users/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const userData = await response.json();

        // Extract necessary data from userData
        const userBalance = userData.shares; // Adjust based on your actual response structure
        const userLevel = userData.level; // Adjust based on your actual response structure

        // Set tap balance and current level
        setTapBalance(userBalance);
        setCurrentLevel(userLevels.find(level => level.name === userLevel));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const handlePrevious = () => {
    if (displayedLevelIndex > 0) {
      setDisplayedLevelIndex(displayedLevelIndex - 1);
      setCurrentLevel(userLevels[displayedLevelIndex - 1]);
    }
  };

  const handleNext = () => {
    if (displayedLevelIndex < userLevels.length - 1) {
      setDisplayedLevelIndex(displayedLevelIndex + 1);
      setCurrentLevel(userLevels[displayedLevelIndex + 1]);
    }
  };

  const formatNumberCliam = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 10000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 10000000).toFixed(3).replace(".", ".") + " T";
    }
  };

  useEffect(() => {
    const handleBackButtonClick = () => {
      setShowLevels(false);
    };

    if (showLevels) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);
    } else {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
    }

    return () => {
      window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
    };
  }, [showLevels, setShowLevels]);

  return (
    <>
      {showLevels ? (
        <div className="fixed z-50 left-0 right-0 top-0 bottom-0 flex justify-center taskbg px-[16px] h-full">
          <div className="w-full flex flex-col items-center justify-start pt-6">
            <div className='flex w-full flex-col items-center text-center'>
              <h1 className={`text-[20px] font-semibold`}>
                {currentLevel?.name}
              </h1>
              <p className='text-[#9a96a6] text-[14px] font-medium pt-1 pb-10 px-4'>
                Your number of shares determines the league you enter:
              </p>
            </div>

            <div className="w-full flex justify-between items-center px-6 relative">
              <div className="flex items-center justify-center absolute left-0">
                {displayedLevelIndex > 0 && (
                  <button className="text-[#e8e8e8] hover:text-[#c4c4c4]" onClick={handlePrevious}>
                    <MdOutlineKeyboardArrowLeft size={40} className='' />
                  </button>
                )}
              </div>

              <div className="flex flex-1 items-center justify-center">
                <img src={currentLevel?.icon} alt={currentLevel?.name} className="w-[200px] h-auto" />
              </div>

              <div className="flex items-center justify-center absolute right-0">
                {displayedLevelIndex < userLevels.length - 1 && (
                  <button className="text-[#e8e8e8] hover:text-[#c4c4c4]" onClick={handleNext}>
                    <MdOutlineKeyboardArrowRight size={40} className='' />
                  </button>
                )}
              </div>
            </div>

            <div className={`w-full overflow-hidden`}>
              {currentLevel && (
                <>
                  <p className="text-[18px] w-full text-center font-semibold text-[#c6c6c6] px-[20px] pt-[35px] pb-[10px]">{tapBalance} / {formatNumberCliam(currentLevel.tapBalanceRequired)}</p>
                  <div className='w-full px-[44px]'>
                    <div className='flex w-full mt-2 p-[4px] items-center bg-energybar rounded-[10px] border-[1px] border-[#403f5c]'>
                      <div className={`h-[8px] rounded-[8px] bg-btn`} style={{ width: `${(tapBalance / currentLevel.tapBalanceRequired) * 100}%` }}/>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Levels;

