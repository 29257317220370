import React, { createContext, useState, useEffect } from "react";

const EnergyContext = createContext();

const refillTime = 1000; // 1 second in milliseconds

const EnergyProvider = ({ children }) => {
  const [energy, setEnergy] = useState(500);
  const [displayEnergy, setDisplayEnergy] = useState(500);
  const [userid, setUserid] = useState(null);
  const [balance, setBalance] = useState(0);
  const [batterylev, setBattery] = useState(1);
  const [chargeLevel, setChargeLevel] = useState(1);
  const fullcharge = batterylev * 500;
  const charge = chargeLevel;

  const fetchUserData = async (userid) => {
    try {
      const response = await fetch(`https://whatapi.cloud/users/${userid}`);
      if (!response.ok) {
        throw new Error("Error fetching user stats");
      }
      const data = await response.json();
      console.log("Fetched user data:", data);
      setBalance(data.shares);
      setEnergy(data.energy);
      setDisplayEnergy(data.energy);
      setBattery(data.energy_level);
      setChargeLevel(data.charge_level);
    } catch (e) {
      console.error("Error fetching user stats:", e);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const telegramUseri = queryParams.get("hip");
    if (telegramUseri) {
      setUserid(telegramUseri);
      fetchUserData(telegramUseri);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (energy < fullcharge) {
        setEnergy((prevEnergy) => {
          const newEnergy = Math.min(prevEnergy + charge, fullcharge);
          setDisplayEnergy(newEnergy);
          return newEnergy;
        });
      }
    }, refillTime);
    return () => clearInterval(interval);
  }, [energy, fullcharge, charge]);

  return (
    <EnergyContext.Provider
      value={{
        energy,
        setEnergy,
        displayEnergy,
        setDisplayEnergy,
        setUserid,
        setBalance,
        setBattery,
        setChargeLevel,
      }}
    >
      {children}
    </EnergyContext.Provider>
  );
};

export { EnergyContext, EnergyProvider };

